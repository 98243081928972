import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import {useTranslation} from 'react-i18next';
import css from './legal_documents.module.scss';
import Footer from "../components/shared/Footer/Footer";
import {getRoutes, legalDocuments} from "../components/shared/routes";
import BoxHeader from "../components/shared/BoxHeader/BoxHeader";
import DocPdfIco from "../images/icons/document_pdf.svg";
import OptiwaiLogo from "../images/logos/optiwai_logo.svg";
import OptiwaiPlusLogo from "../images/logos/optiwai_plus_full_logo.svg";

const LegalDocumentsPage = () => {
  const { t } = useTranslation();
  const pageTitle = `${getRoutes().LegalDocuments.pageTitle}`;

  const data = [{
    logo: OptiwaiLogo,
    logoAlt: '',
    files: [{
        name: "Privacy Policy",
        url: legalDocuments.optiwai.privacyPolicy
    }, {
        name: "Terms and Conditions",
        url: legalDocuments.optiwai.termsAndConditions
    }, {
        name: "Subusers",
        url: legalDocuments.optiwai.subusers
    }]

}, {
    logo: OptiwaiPlusLogo,
    logoAlt: '',
    files: [{
        name: "Privacy Policy",
        url: legalDocuments.optiwaiplus.privacyPolicy
    }, {
        name: "Terms and Conditions",
        url: legalDocuments.optiwaiplus.termsAndConditions
    }]
}];

const renderAppFiles = (files = []) => {
    return files.map(file => {
        const { name, url } = file;
        return (
            <a href={url} target="_blank" key={`legal_documents_${url}`}>
                <div className={css.linkWrapper}>
                    <div className={css.docLogo}>
                        <img src={DocPdfIco} alt="" />
                    </div>
                    <div className={css.docText}>
                        {name}
                    </div>
                </div>
            </a>
        );
    });
};

const renderApps = () => {
    return data.map((app, index) => {
        const { logo, logoAlt, files = [] } = app;
        return (
          <div className={css.app} key={`legal_documents_app_${index}`}>
              <div className={css.logoWrapper}>
                  <img src={logo} alt={logoAlt} />
              </div>
              <div className={css.linksWrapper}>
                  {renderAppFiles(files)}
              </div>
          </div>
        );
    });
};

  return (
    <Layout>
      <SEO title={pageTitle} description={'Privacy policy T&C'} />

      <div className={css.wrapper}>

        <div className={css.LegalDocuments}>
        <div className={css.wrapper}>
        <BoxHeader headerType={'h1'} lineColor={'green'} > Legal Documents </BoxHeader>
        <div className={css.apps}>
            <div className={css.appsInner}>{renderApps()}</div>
        </div>
    </div>
        </div>

        <div className={css.Footer}>
          <Footer/>
        </div>

      </div>
    </Layout>
  );
};

export default LegalDocumentsPage;

